import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import OurTeamContainer from "../containers/OurTeamContainer"

const OurTeam = (props) => (
  <Layout 
  pageInfo={{ pageName: "Our Team" }} 
  styleClass="home-page" 
  mainClass="main-about"
  location={props.location.pathname}>
    <SEO title="Our Team" />
    <OurTeamContainer />
  </Layout>
)

export default OurTeam
