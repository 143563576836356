import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/our story/banner.png'
import { Row, Col, Container } from 'react-bootstrap'
import ramesh from '../images/slicing/our story/ramesh.jpg'
import vikas from '../images/slicing/our story/vikas.jpg'
import akhilesh from '../images/slicing/our story/akhilesh.jpg'
class OurTeamContainer extends Component {
  render() {
    return (
      <>
        <BannerImage
          activeTab="Leadership Team"
          bannerImage={banner}
          text="Meet Our Leadership" />
        <Container className="py-5">
          <Row className="pt-3">
            <Col className='font-weight-bold pt-5 text-center d-md-none'>
              <h3 className="text-standard font-weight-bold d-none d-md-block d-xl-block">Founder & CEO</h3>
              <img src={vikas} width="260px" height="270px" alt="Not Found" />
              <h2 className='p-2 text-standard'>
                Vikas Mittal
              </h2>
            </Col>
            <Col>
              <h3 className="text-standard font-weight-bold">Founder & CEO</h3>
              <p className="industries-paragrapgh">
                An IIT Delhi alumnus working in software engineering for last 2 decades. His focus area
                includes the development of automation solutions for Software Testing Lifecycle and
                DevOps using the latest technological innovations in Machine Learning and Predictive
                Analytics.
              </p>
              <p className="industries-paragrapgh">
                Leader by spirit, Vikas has spearheaded the teams of engineering professionals as large as
                1000+ consultants, helping and supporting them all in accomplishing their personal as
                well as company goals. His expertise in architecture, design, and implementation of
                solutions enables him to serve high-end customers well.
              </p>
              <p className="industries-paragrapgh">
                In his last role, he was the chief technology evangelist for a Netherlands based leading
                software consulting company.
              </p>
            </Col>
            <Col className='font-weight-bold pt-5 text-center d-none d-md-block d-xl-block'>
              <img src={vikas} width="260px" height="270px" alt="Not Found" />
              <h2 className='p-2 text-standard'>
                Vikas Mittal
              </h2>
            </Col>
          </Row>
        </Container>

        <Container fluid className="standard-background">
          <Container className="py-5">
            <Row className="pt-3 ">
              <Col className='font-weight-bold pt-5 text-center'>
                <img src={ramesh} width="200px" alt="Not Found" />
                <h2 className='text-white p-2'>
                  Ramesh Subramanian
                </h2>
              </Col>
              <Col xs="12" md="6">
                <h3 className="text-dark font-weight-bold">Chief Growth & Strategy Advisor</h3>
                <p className="industries-paragrapgh">
                  An industry veteran with over 35 years of
                  experience in various capacities. A
                  graduate of IIT-Mumbai and IIM-Bangalore,
                  he has worked with some of the leaders
                  such as Accenture, Capgemini, Tatas, etc.
                  Ramesh is a “hands-on” leader with a blend
                  of management and technology experience
                  in varied situations
                </p>
                <p className="industries-paragrapgh">
                  His industry experience spans the financial sector, manufacturing, IT, EPC and similar domains. His last corporate tenure was as Global CEO with a US headquartered company focused on User Experience.
                </p>
              </Col>

            </Row >
          </Container>

        </Container>
        <Container fluid>
          <Container className="py-5">
            <Row className="pt-3">
              <Col className='font-weight-bold pt-5 text-center d-md-none'>
                <img src={akhilesh} width="200px" alt="Not Found" />
                <h2 className='text-standard p-2'>
                  Akhilesh Chaturvedi
                </h2>
              </Col>
              <Col>
                <h3 className="text-standard font-weight-bold">Chief Technology Transformation Advisor</h3>
                <p className="industries-paragrapgh">
                  An industry veteran with over 25 years of experience as a technology architect. A engineering graduate from Bangalore university with masters from IIM-Bangalore. He has worked with some of the leaders such as Accenture, Cognizant in a variety of roles from enterprise architect to client delivery manager.

                </p>
                <p className="industries-paragrapgh">
                  Akhilesh is a leadership coach for technology transformation and enterprise architecture, extends to digital transformation, incorporating Internet of Things, machine learning, block chain, analytics, and big data solutions.

                </p>
              </Col>
              <Col className='font-weight-bold pt-5 text-center d-none d-md-block d-xl-block'>
                <img src={akhilesh} width="200px" alt="Not Found" />
                <h2 className='text-standard p-2'>
                  Akhilesh Chaturvedi
                </h2>
              </Col>
            </Row>
          </Container>
        </Container>
        <hr />
      </>
    )
  }
}

export default OurTeamContainer